<template>

  <p v-if="titel" class="font-text text-2xl p-4 mt-4 mb-0"> {{titel}} </p>

  <div class="w-full flex flex-wrap p-2">

    <!-- :class="[{'bg-red-500': selectedCategories.includes(category.ID)}, borderColor]" -->

    <!-- guard expression, seen here: https://michaelnthiessen.com/conditional-class-binding-vue -->
    <div
    :class="[selectedCategories.includes(category.ID) && bgColor, borderColor]"
    class="cursor-pointer text-center border-2 m-1 p-2 rounded-xl"
    v-for="category in categories"
    @click="submit(category.ID)"
    >

    <p> {{category.Kategorie}} </p>

  </div>

</div>
</template>

<script>
export default {
  name: 'CategoryInput',
  emits: ['addInput'],
  props: ['type', 'selectedCategories', 'color', 'titel'],
  data() {
    return {
      myinput: '',
      selected: [],

      borderColor: 'border-' + this.color +'-500',
      bgColor: 'bg-' + this.color + '-500'
    }
  },
  methods: {

    submit(category){

      this.$emit('addInput', category);

    },

  },
  computed: {

    categories(){

      var filteredCategories = [];
      var that = this;

      if(this.$store.state.kategorien){

        filteredCategories = this.$store.state.kategorien.filter(function (kategorie) {
          return kategorie.Typ == that.type;
        });

      }

      return filteredCategories;

    },

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
