<template>

  <nav class="m-4 mb-8 text-center text-xl">
    <!-- <router-link to="/">Orakel</router-link> | -->
    <router-link to="/">Übersicht</router-link> |
    <!-- <router-link to="/erfassen">Migrieren</router-link> | -->
    <router-link to="/publishing">Publishing</router-link> |

    <router-link to="/impressum">Impressum</router-link>
    <!-- <router-link to="/zahlen">Zahlen-Ziehen</router-link> -->


  </nav>

</template>

<script>

export default {
  name: 'Navigation',
  components: {

  },
  data() {
    return {

    }
  },
  methods: {


  },
  mounted() {

    // this.initialPW();

  }

}


</script>

<style scoped>

.router-link-active{

  color: red;

}

</style>
