<template>

  <div v-if="this.$store.state.untrackedFetchComplete">

    <div class="h-48 overflow-scroll cursor-pointer bg-gray-100">

      <div class="m-2 hover:text-red" v-for="article in untrackedArticles" @click="selectArticle(article)">

        <TransformDate :date="article.Datum" />

        <p>{{article.Titel}} </p>

      </div>

    </div>

  </div>

  <div v-if="this.currentArticle" class="border border-gray-500 mt-8 p-8">

    <TransformDate :date="currentArticle.Datum" />
    <h2> <a target="_blank" :href="currentArticle.Url"> {{currentArticle.Titel}} </a> </h2>

    <!-- <DropdownInput
      @addInput="writeInput($event, 'kanal')"
      dbHandle="Kanal"
      storeArray="kanaele"
      myplaceholder="Kanal"
      > </DropdownInput>

      <DropdownInput
      @addInput="writeInput($event, 'position')"
      dbHandle="Position"
      storeArray="positionen"
      myplaceholder="Position"
      > </DropdownInput> -->


    <!-- <h2 class="p-4 mt-4 ">📚 Kategorien</h2> -->

    <!-- <h2 class="p-4 mt-16">📜 Umsetzungsform</h2> -->

    <CategoryInput @addInput="writeCategory($event)" type="Umsetzungsform" :selectedCategories="this.categories"
      color="red" titel="Textform"> </CategoryInput>

    <!-- <h2 class="p-4 mt-16"> </h2> -->

    <CategoryInput @addInput="writeCategory($event)" type="Multimedia" :selectedCategories="this.categories" color="red"
      titel="Multimedia"> </CategoryInput>

    <!-- <h2 class="p-4 mt-16">🎯 Strategische Schwerpunkte</h2> -->

    <CategoryInput @addInput="writeCategory($event)" type="Thema" :selectedCategories="this.categories" color="red"
      titel="Themen"> </CategoryInput>


    <CategoryInput @addInput="writeCategory($event)" type="Schwerpunkt" :selectedCategories="this.categories"
      color="red" titel="Strategische Schwerpunkte"> </CategoryInput>

    <!-- <h2 class="p-4 mt-16"> ✍️ Notizen</h2> -->

    <TextInput @addInput="writeInput($event, 'notizen')" myplaceholder="Notizen" class="w-full"> </TextInput>

    <button @click="sendForm()"
      class="text-left mt-8 bg-red-300 hover:bg-red-500 text-white text-xl font-bold py-4 px-8 rounded">
      Tracking starten
    </button>

    <p class="mt-4" v-if="message"> {{this.message}} <span
        class="underline text-red-500 cursor-pointer" @click="emptyForm()"> Schliessen </span> </p>

  </div>

</template>

<script>
import TextInput from '@/components/TextInput.vue'
import NumberInput from '@/components/NumberInput.vue'
import CategoryInput from '@/components/CategoryInput.vue'
import DropdownInput from '@/components/DropdownInput.vue'
import TitleWithImage from '@/components/TitleWithImage.vue'
import TransformDate from '@/components/TransformDate.vue'

import Datepicker from 'vue3-datepicker'
import { ref } from 'vue'

export default {
  name: 'InsertFormular',
  components: {
    TextInput,
    NumberInput,
    Datepicker,
    CategoryInput,
    DropdownInput,
    TitleWithImage,
    TransformDate
  },
  props: [],
  data() {
    return {
      titel: '',
      url: '',
      MAIN_DE: '',
      MAIN_FR: '',
      MAIN_IT: '',
      KUNDEN_DE: '',
      KUNDEN_FR: '',
      KUNDEN_IT: '',
      likes: '',
      dislikes: '',
      kommentare: '',
      kanal: 3,
      position: 1,
      notizen: '',
      categories: [],
      datum: ref(new Date()),
      message: false,

      currentArticle: false,

    }
  },
  methods: {

    fetchUntrackedArticles() {

      this.$store.dispatch('fetchUntrackedArticles');
      this.$store.dispatch('fetchPianoNewArticles');

    },

    selectArticle(article) {

      console.log(article);

      this.currentArticle = article;

    },

    writeInput(input, field) {

      this[field] = input;

    },

    writeCategory(category) {

      if (this.categories.indexOf(category) == -1) {

        this.categories.push(category);

      } else {

        this.categories.splice(this.categories.indexOf(category), 1);

      }

      // console.log(this.categories);

    },

    emptyForm() {

      this.fetchUntrackedArticles();
      
      this.notizen = '';
      this.categories = [];
      this.message = false;
      this.currentArticle = false;

    },

    sendForm(state, status) {

      // Build formData object.
      let formData = new FormData();

      formData.append('id', this.currentArticle.ID);
      formData.append('position', this.position);
      formData.append('kanal', this.kanal);
      formData.append('notizen', this.notizen);
      formData.append('kategorien', JSON.stringify(this.categories));

      fetch("https://sbbanalytics.ch/php/publishing.php",
        {
          body: formData,
          method: "post"
        })
        .then(response => response.text())
        .then(response => {

          this.message = response;

        })
        .catch(function (response) {
          //handle error
          alert("Uh! Da ist etwas schief gelaufen")
          // console.log(response)
        });

    },

  },
  computed: {

    untrackedArticles() {

      return this.$store.state.untrackedArticles;

    }

  },

  mounted() {

    this.fetchUntrackedArticles();

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
