<template>
  <div v-if="!isJuly2023OrLater" class="bg-red-500 text-white text-lg p-8">
    <div class="max-w-4xl">
      <p class="font-bold">
        SBB Analytics wird am 30. Juni um 23:59 Uhr eingestellt.
      </p>

      <p class="mt-2">
        Alle Daten werden unwiderruflich gelöscht. Falls Du die Performance oder
        Diagramme bestimmter Artikel aufbewahren / exportieren möchtest,
        arbeitest du am besten mit Screenshots.
      </p>

      <p class="mt-4">
        In Zukunft wird für die Artikel-Analyse hoffentlich ein Dashboard in
        Piano Analytics zur Verfügung stehen. Weitere Informationen erhältst du
        von der AG Analytics oder den Verantwortlichen bei PLK.
      </p>

      <!-- <span @click="showMore = !showMore" class="text-white underline cursor-pointer">
        
        Mehr erfahren
      
      </span> -->

      <div v-if="showMore" class="mt-2 p-4">
        <p class="mt-2">
          Additional information about the shutdown. This could include
          alternative services, data migration options, etc.
        </p>
      </div>
    </div>
  </div>

  <div v-if="isJuly2023OrLater">
    <!-- <img
      src="@/assets/icons/chart-column-trend-large.svg"
      class="w-1/12 mx-auto"
      alt=""
    />

    <h1 class="text-center mt-8">SBB Analytics</h1> -->

    <div class="mt-4 text-left w-full lg:w-1/3 mx-auto">
      <div class="bg-white rounded px-8 pt-6 pb-8 mb-4">
        <div class="mb-8">
          <p class="block text-md mb-6">
            SBB Analytics wurde Ende Juni 2023 eingestellt.
          </p>

          <p class="mt-4">Alle Daten wurden unwiderruflich gelöscht.</p>

          <p class="mt-4">
            Im Sommer 2022 wurde dem Newsroom versprochen, dass für die Analyse auf
            Artikel-Ebene ein benutzerfreundliches Dashboard in Piano-Analytics
            erstellt wird. Hoffentlich hat PLK das Versprechen inzwischen gehalten.
          </p>

          <a class="mt-4 block underline" target="_blank" href="https://www.nickschnee.ch"> www.nickschnee.ch </a>
          
          <a class="mt-4 block underline" target="_blank" href="https://www.linkedin.com/in/nick-schneeberger-814645154?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3Bf2n3S13TQNKK2wUhPx9TlQ%3D%3D"> www.linkedin.com/nickschnee </a>

        </div>
      </div>
    </div>

    <div class="w-1/2 mx-auto bg-red-300">
      <p class="text-white text-lg text-center mt-8">{{ loginError }}</p>
    </div>
  </div>

  <password v-else-if="!this.$store.state.login"> </password>

  <div v-else>
    <Navigation />

    <div class="w-5/6 mx-auto p-10">
      <div class="w-1/2">
        <FilterBar />
      </div>
    </div>

    <div class="w-5/6 mx-auto flex">
      <div class="w-full bg-white px-4 rounded">
        <!-- <LineChart> </LineChart> -->

        <NewArticleList @edit="showEditFields($event)" />
      </div>

      <!-- <div class="w-1/3 bg-white">

        <ArticleDetails> </ArticleDetails>

      </div> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import LineChart from "@/components/LineChart.vue";
import FilterBar from "@/components/FilterBar.vue";
import Navigation from "@/components/Navigation.vue";
import Password from "@/components/Password.vue";
import NewArticleList from "@/components/NewArticleList.vue";
import ArticleDetails from "@/components/ArticleDetails.vue";

export default {
  name: "AllView",
  components: {
    LineChart,
    FilterBar,
    Navigation,
    Password,
    NewArticleList,
    ArticleDetails,
  },
  data() {
    return {
      showMore: false,
    };
  },
  methods: {
    onload: function () {
      this.$store.dispatch("getData");
      // this.$store.dispatch('fetchPianoPerformance');
      // this.$store.dispatch('fetchPianoNewArticles');
    },
  },
  mounted: function () {
    this.onload();
  },

  computed: {
    isJuly2023OrLater() {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth(); // Month is 0-indexed, so January is 0, and June is 5

      // Check if it's July 2023 or later
      return year > 2023 || (year === 2023 && month >= 6);
    },
  },
};
</script>
