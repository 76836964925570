<template>

  <div class="w-1/3 p-4">

    <label class="font-text text-2xl" :for="myinput"> {{myplaceholder}} </label><br>

    <select
    class="font-thin text-left mt-4 text-2xl w-full bg-white border-solid border-b-4 border-red-500 focus:outline-none"
    @input="submit($event)" name="item" :id="myinput" v-model="item">

    <option
    class="font-thin text-left mt-4 text-2xl w-full border-solid border-b-4 border-red-500 focus:outline-none"
    v-for="item in items" :value="item.ID">

    {{item[dbHandle]}}

  </option>

</select>

</div>

</template>

<script>
export default {
  name: 'DropdownInput',
  emits: ['addInput'],
  props: ['dbHandle', 'storeArray', 'myplaceholder'],
  data() {
    return {
      myinput: '',
      // item ist die ID des Standardwerts
      item: 1,
    }
  },
  methods: {

    submit(id){

      // console.log("Der Input ist: " + event.target.value);

      this.$emit('addInput', event.target.value);

    },

    onload(){

      this.$emit('addInput', this.item);

    }

  },
  computed: {

    items(){

      // console.log(this.$store.state[this.storeArray]);

      return this.$store.state[this.storeArray];

    },

  },

  mounted: function(){

    this.onload();

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
