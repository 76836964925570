<template>

  <div class="mb-16">

    <div class="mb-8">

      <TitleWithImage
      class="mb-4"
      title="Sortieren"
      image="chevron-small-down-circle-medium"/>

      <button
      @click="filterDate()"
      class="cursor-pointer border-2 rounded-xl text-center mr-2 p-2 w-1/6 hover:border-red-200"
      :class="{ selected : currentFilter == 1 }"
      > Datum </button>

      <button
      @click="filterTop()"
      class="cursor-pointer border-2 rounded-xl text-center mr-2 p-2 w-1/6 hover:border-red-200"
      :class="{ selected : currentFilter == 2 }"
      > Top </button>

      <button
      @click="filterFlop()"
      class="cursor-pointer border-2 rounded-xl text-center p-2 w-1/6 hover:border-red-200"
      :class="{ selected : currentFilter == 3 }"
      > Flop </button>

    </div>

    <div class="">

      <DropdownFilter
      class="w-1/2"
      @addInput="changeSpalte($event)"
      dbHandle="Spalte"
      storeArray="spalte"
      myplaceholder="Sortieren nach Spalte"
      :array="['MAIN_DE', 'MAIN_FR', 'MAIN_IT', 'KUNDEN_DE', 'KUNDEN_FR', 'KUNDEN_IT']"
      > </DropdownFilter>

    </div>

    <div v-if="this.currentFilter == 2 || this.currentFilter == 3" class="my-4 messagebox">
      <p class="font-light"> <i> Es werden nur Artikel angezeigt, die in der Zielgruppe {{sortKey}} mehr als 0 Visitors haben.</i> </p>
    </div>

  </div>

</template>

<script>
import DropdownFilter from '@/components/DropdownFilter.vue'
import TitleWithImage from '@/components/TitleWithImage.vue'

export default {
  name: 'Sorters',
  emits: [],
  props: [],
  components: {
    DropdownFilter,
    TitleWithImage
  },
  data() {
    return {

      sortKey: 'MAIN_DE',
      currentFilter: 1,

    }
  },
  methods: {

    filterDate(){
      this.currentFilter = 1;

      let data = this.$store.state.data;

      data = data.slice().sort(this.sortDate);

      console.log(data);

      this.$store.commit('filterData', data);

    },

    changeSpalte(input){

      // console.log(input);
      this.sortKey = input;

      // do this only after data has been fetched
      if(this.$store.state.data){

        switch (this.currentFilter) {
          case 1:
          this.filterDate();
          // this.filterDate();
          break;
          case 2:
          this.filterTop();
          break;
          case 3:
          this.filterFlop();
        }

      }

    },

    sortTop(a, b) {
      if (parseInt(a[this.sortKey]) > parseInt(b[this.sortKey])) {
        return -1;
      }
      if (parseInt(a[this.sortKey]) < parseInt(b[this.sortKey])) {
        return 1;
      }
      // a muss gleich b sein
      return 0;
    },

    sortFlop(a, b) {
      if (parseInt(b[this.sortKey]) > parseInt(a[this.sortKey])) {
        return -1;
      }
      if (parseInt(b[this.sortKey]) < parseInt(a[this.sortKey])) {
        return 1;
      }
      // a muss gleich b sein
      return 0;
    },

    sortDate(a, b) {

      if (new Date(a.Datum).getTime() > new Date(b.Datum).getTime()) {
        return -1;
      }
      if (new Date(a.Datum).getTime() < new Date(b.Datum).getTime()) {
        return 1;
      }
      // a muss gleich b sein
      return 0;
    },

    filterFlop(){

      this.currentFilter = 3;

      // filter out all articles that have 0 visitors in the current sortKey (e.g. MAIN_DE)
      let filtered = this.removeZeros();

      // sort those articles by sortKey (e.g. MAIN_DE)
      filtered = filtered.slice().sort(this.sortFlop);

      this.$store.commit('filterData', filtered);

    },

    filterTop(){

      this.currentFilter = 2;

      // filter out all articles that have 0 visitors in the current sortKey (e.g. MAIN_DE)
      let filtered = this.removeZeros();

      // sort those articles by sortKey (e.g. MAIN_DE)
      filtered = filtered.slice().sort(this.sortTop);

      this.$store.commit('filterData', filtered);

    },

    removeZeros(){

      let data = this.$store.state.data;

      var that = this;

      let filtered = data.filter(function (article) {
        return article[that.sortKey] > 0;
      });

      return filtered;

    }

  },
  computed: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.selected{

  /* background-color: #FF6666 !important; */
  border-color: #FF6666 !important;

}

</style>
