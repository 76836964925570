<template>

  <!-- <p>Differenz: {{difference(likes, dislikes)}}</p> -->

  <div class="flex">

    <img class="inline" src="@/assets/icons/thumb_up.svg">
    <p class="py-2"> {{likes}}  </p>

  </div>

  <div class="flex">

    <img class="inline" src="@/assets/icons/thumb_down.svg">
    <p class="py-2"> {{dislikes}}  </p>

  </div>

  <div class="flex">

    <img class="inline" src="@/assets/icons/comment.svg">
    <p class="py-2"> {{comments}}  </p>

  </div>

</template>

<script>
export default {
  name: 'SocialMeter',
  props: ['likes', 'dislikes', 'comments'],
  data() {
    return {

    }
  },
  methods: {

    difference(likes, dislikes){

      let interaction = likes - dislikes;

      let icon;


      if(interaction > 0){

        icon = '👍';

      } else {

        icon = '👎';

        interaction = Math.abs(interaction);

      }

      return interaction + icon;

    }

  },
  computed: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
