<template>

  <TitleWithImage
  class="mb-4"
  title="Filter"
  image="filter-medium"/>

  <!-- Date Filter Button -->
  <button :class="{'mr-4': !dateFilterActive}" @click="showModal = true" class="button-filter-left"> {{dateFilterButtonText}} </button>
  <button v-if="dateFilterActive" @click="resetDateFilter()" class="button-filter-right"> X </button>

  <!-- <button @click="showChannelModal = true" class="button-filter-left"> {{channelFilterButtonText}} </button>
  <button v-if="channelFilterActive" @click="resetChannelFilter()" class="button-filter-right"> X </button> -->

  <Teleport to="body">
    <!-- use the modal component, pass in the prop -->
    <ModalFilterDate
    :show="showModal"
    @close="showModal = false"
    @activeFilter="dateDataEntry($event)"
    @filter="activeFilterList($event)"
    >
    <template #header>
      <h3>Artikel nach Datum filtern</h3>
    </template>
  </ModalFilterDate>

  <ModalFilterChannel
  :show="showChannelModal"
  @close="showChannelModal = false"
  @activeFilter="channelDataEntry($event)"
  @filter="activeFilterList($event)"
  >
  <template #header>
    <h3>Artikel nach Kanal filtern</h3>
  </template>
</ModalFilterChannel>
</Teleport>

<!-- ENDE MODAL WITH TELEPORT-->


<!-- Sorter-Bar -->
<Sorters
class="mt-16"/>

<p class="my-8"> <span class="text-4xl text-red-500"> {{this.$store.state.data.length}} </span> Artikel werden angezeigt</p>

</template>

<script>
import Datepicker from 'vue3-datepicker'
import ModalFilterDate from "@/components/ModalFilterDate.vue";
import ModalFilterChannel from "@/components/ModalFilterChannel.vue";
import Sorters from '@/components/Sorters.vue'
import TitleWithImage from '@/components/TitleWithImage.vue'
import ModalFilter from '@/components/ModalFilter.vue'
import { ref } from 'vue'

export default {
  name: 'FilterBar',
  props: [],
  components: {
    Datepicker,
    ModalFilter,
    Sorters,
    TitleWithImage,
    ModalFilterDate,
    ModalFilterChannel
  },
  data() {
    return {

      dateFilterActive: false,
      dateFilterButtonText: 'Datum',

      dateFrom: '',
      dateTo: '',
      channel: 'MAIN',

      channelFilterActive: false,
      channelFilterButtonText: 'Kanal',

      showModal: false,
      showChannelModal: false,

      activeFilters: [],

    }
  },
  methods: {

    // master function to run all filters
    runFilters(){

      // before applying any filters, restore the source array
      this.restoreSource();

      // check if datefilter was used
      if (this.activeFilters.includes('dateFilter')){


        this.filterFromDate();
        this.filterToDate();

      }

      if (this.activeFilters.includes('channelFilter')){

        this.filterChannel();

      }

    },

    activeFilterList(event){

      let activeFilter = this.activeFilters.indexOf(event[0]);

      if(activeFilter === -1){

        this.activeFilters.push(event[0]);

        console.log('adding ' + event[0] + ' to list')

      } else {

        console.log('Filter ' + event[0] + ' already active')

        // do nothing

      }

      // after updating the filter list, run the filters
      this.runFilters();

    },

    resetActiveFilterList(filter){

      let index = this.activeFilters.indexOf(filter);

      if (index > -1) {

        this.activeFilters.splice(index, 1);

        console.log('Removed ' + filter);

      } else {

        // do nothing

      }

      // after updating the filter list, run the filters
      this.runFilters();

    },

    restoreSource(){

      let source = this.$store.state.source

      this.$store.commit('filterData', source);

    },

    // all data entering this component will be set here
    // all data entering this component will be set here
    // all data entering this component will be set here
    // all data entering this component will be set here
    // all data entering this component will be set here
    // all data entering this component will be set here
    // all data entering this component will be set here
    // all data entering this component will be set here
    // all data entering this component will be set here
    // all data entering this component will be set here


    dateDataEntry(event){

      let options = { year: 'numeric', month: 'short', day: 'numeric' };
      let from  = new Date(event[0]);
      let to = new Date(event[1]);

      this.dateFrom = event[0];
      this.dateTo = event[1];

      this.dateFilterActive = true;
      this.dateFilterButtonText = from.toLocaleDateString("de-CH", options) + ' bis ' + to.toLocaleDateString("de-CH", options);
    },

    channelDataEntry(event){

      this.channel = event;

      this.channelFilterActive = true;

      this.channelFilterButtonText = event;


    },

    // resetting filters by pressing the button
    // resetting filters by pressing the button
    // resetting filters by pressing the button
    // resetting filters by pressing the button
    // resetting filters by pressing the button
    // resetting filters by pressing the button
    // resetting filters by pressing the button
    // resetting filters by pressing the button
    // resetting filters by pressing the button

    resetDateFilter(){

      this.dateFilterActive = false;
      this.dateFilterButtonText = 'Datum';

      let data = this.$store.state.source;

      this.resetActiveFilterList('dateFilter');

    },

    resetChannelFilter(){

      this.channelFilterActive = false;
      this.channelFilterButtonText = 'Kanal';

      let data = this.$store.state.source;

      this.resetActiveFilterList('channelFilter');

    },


    // all filters to run come here
    // all filters to run come here
    // all filters to run come here
    // all filters to run come here
    // all filters to run come here
    // all filters to run come here
    // all filters to run come here
    // all filters to run come here
    // all filters to run come here


    filterFromDate(){

      console.log('Filtering: FromDate');

      let data = this.$store.state.data;

      var that = this;

      let filtered = data.filter(function (article) {
        return new Date(article.Datum).getTime() > that.dateFrom.getTime();
      });

      console.log(filtered);

      this.$store.commit('filterData', filtered);

    },

    filterToDate(){

      console.log('Filtering: ToDate');

      let data = this.$store.state.data;

      var that = this;

      let filtered = data.filter(function (article) {
        return new Date(article.Datum).getTime() < that.dateTo.getTime();
      });

      console.log(filtered);

      this.$store.commit('filterData', filtered);

    },

    filterChannel(){

      // filter channel only if channel is not 'alle'
      if (this.channel !== 'alle'){

        console.log('Filtering: Channel');

        let data = this.$store.state.data;

        var that = this;

        console.log('Der Kanal ist: ' + this.channel);

        let filtered = data.filter(function (article) {
          return article.Kanal == that.channel;
        });

        console.log(filtered);

        this.$store.commit('filterData', filtered);

      }

    },

  },
  computed: {

  },

  mounted: function(){


  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
