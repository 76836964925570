<template>

  <div class="mb-16">

    <div class="mb-8">

      <TitleWithImage
      class="mb-4"
      title="Sortieren"
      image="chevron-small-down-circle-medium"/>

      <button
      @click="filterStatus()"
      class="cursor-pointer border-2 rounded-xl text-center mr-2 p-2 w-1/6 hover:border-red-200"
      :class="{ selected : currentFilter == 1 }"
      > Offene </button>

      <!-- <button
      @click="filterDate()"
      class="cursor-pointer border-2 rounded-xl text-center mr-2 p-2 w-1/6 hover:border-red-200"
      :class="{ selected : currentFilter == 2 }"
      > Letzte </button> -->

      <button
      @click="filterAlle()"
      class="cursor-pointer border-2 rounded-xl text-center mr-2 p-2 w-1/6 hover:border-red-200"
      :class="{ selected : currentFilter == 3 }"
      > Alle </button>

      <button
      @click="filterUnkategorisierte()"
      class="cursor-pointer border-2 rounded-xl text-center mr-2 p-2 w-1/6 hover:border-red-200"
      :class="{ selected : currentFilter == 4 }"
      > Unkategor. </button>

    </div>

  </div>

</template>

<script>
import DropdownFilter from '@/components/DropdownFilter.vue'
import TitleWithImage from '@/components/TitleWithImage.vue'

export default {
  name: 'Sorters',
  emits: [],
  props: [],
  components: {
    DropdownFilter,
    TitleWithImage
  },
  data() {
    return {

      currentFilter: 3,

    }
  },
  methods: {

    sortDate(a, b) {

      if (new Date(a.Datum).getTime() < new Date(b.Datum).getTime()) {
        return -1;
      }
      if (new Date(a.Datum).getTime() > new Date(b.Datum).getTime()) {
        return 1;
      }
      // a muss gleich b sein
      return 0;
    },

    sortDateAbst(a, b) {

      if (new Date(a.Datum).getTime() > new Date(b.Datum).getTime()) {
        return -1;
      }
      if (new Date(a.Datum).getTime() < new Date(b.Datum).getTime()) {
        return 1;
      }
      // a muss gleich b sein
      return 0;
    },

    filterDate(){

      this.currentFilter = 2;

      // filter by publishing date (last 14 days)

      if(this.$store.state.data){

        let data = this.$store.state.source;

        let filtered = data.filter(function (article) {

          // days*24*60*60*1000 --> 21 == days

          return (new Date(article.Datum).getTime() > new Date().getTime() - 14*24*60*60*1000 || article.Status == 0);

        });

        // sort articles by date
        // sort articles by date
        // sort articles by date

        let sorted = filtered.slice().sort(this.sortDate);

        this.$store.commit('filterData', sorted);

      }

    },

    filterAlle(){

      this.currentFilter = 3;

      if(this.$store.state.data){

        let data = this.$store.state.source;

        // sort articles by date
        // sort articles by date
        // sort articles by date

        let sorted = data.slice().sort(this.sortDateAbst);

        this.$store.commit('filterData', sorted);

      }


    },

    filterStatus(){

      console.log("running fast!");

      this.currentFilter = 1;

      if(this.$store.state.data){

        let data = this.$store.state.source;

        let filtered = data.filter(function (article) {

          // days*24*60*60*1000 --> 14 == days

          return article.Status == 0;

        });

        // sort articles by date
        // sort articles by date
        // sort articles by date

        let sorted = filtered.slice().sort(this.sortDate);

        this.$store.commit('filterData', sorted);

      }

    },

    filterUnkategorisierte(){

      this.currentFilter = 4;

      if(this.$store.state.data){

        let data = this.$store.state.source;

        let filtered = data.filter(function (article) {

          // days*24*60*60*1000 --> 21 == days

          return article.Kategorien.length == 0;

        });

        // sort articles by date
        // sort articles by date
        // sort articles by date

        let sorted = filtered.slice().sort(this.sortDate);

        this.$store.commit('filterData', sorted);

      }


    },

    initialFilter(){

      var that = this;
      setTimeout(function () { that.filterStatus() } , 100)

    }


  },
  computed: {

  },

  mounted: function(){

    this.initialFilter();

  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.selected{

  /* background-color: #FF6666 !important; */
  border-color: #FF6666 !important;

}

</style>
