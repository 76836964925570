<template>

  <div class="flex">

    <img class="text-left -ml-3" :src="require('@/assets/icons/' + image +'.svg')" alt="">

    <div class="flex h-full ml-2">
      <div class="m-auto">

        <h2 class=""> {{title}} </h2>

      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: 'TitleWithImage',
  emits: [''],
  props: ['title', 'image'],
  data() {
    return {

    }
  },
  methods: {

  },
  computed: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
