<template>
  <div class="">

    <img src="@/assets/icons/chart-column-trend-large.svg" class="w-1/12 mx-auto" alt="">

    <h1 class="text-center mt-8">SBB Analytics</h1>

    <div class="w-1/2 mx-auto bg-red-300">

      <p class="text-white text-lg text-center mt-8"> {{ loginError }}</p>

    </div>
    <div class="mt-16 text-center w-full lg:w-1/3 mx-auto">
      <div class="bg-white rounded px-8 pt-6 pb-8 mb-4">
        <div class="mb-8">
          <p class="block text-gray-700 text-md mb-6" for="emailPrefix">
            Um dich anzumelden, gib den Teil <span class="text-red-500"> vor dem @ </span> deiner SBB E-Mail Adresse ein.
            Wir schicken dir danach einen Link, womit du dich sicher einloggen kannst.
          </p>

          <div class="flex items-center border-b-2 border-red-200 py-2">
            <input v-model="emailPrefix" placeholder="vincent.ducrot"
              class="text-gray-700 myinput text-2xl w-1/2 focus:outline-none" />
            <span class="mx-2 text-gray-700 text-2xl">@sbb.ch</span>
          </div>
        </div>

        <p class="mb-8"> Falls du bereits einen Link erhalten hast, benutze bitte diesen Link! Links sind eine Woche lang
          gültig. </p>

        <button @click="sendMagicLink()"
          class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          LINK SCHICKEN
        </button>
        <p class="mt-8"> {{ message }}</p>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Password',
  components: {

  },
  data() {
    return {
      password: "",
      emailPrefix: "",
      message: "",
      loginError: "",
    }
  },
  methods: {

    sendMagicLink() {

      fetch('https://sbbanalytics.ch/php/email.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },

        body: JSON.stringify({ email: this.emailPrefix })
      })
        .then(response => response.json())
        .then(data => {

          console.log(data)

          if (data.status == 'success') {


            this.message = data.message;

          } else {

            this.message = data.message;

          }

        })
        .catch(error => console.error(error))

    },

    confirmMagicLink() {

      const hash = this.$route.query.login
      console.log('Hash from URL:', hash)

      if (hash !== undefined) {

        fetch('https://sbbanalytics.ch/php/confirm.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },

          body: JSON.stringify({ hash: hash })
        })
          .then(response => response.json())
          .then(data => {

            if (data.success) {

              this.$store.commit('login');

            } else {

              this.loginError = data.message;

            }
            // handle successful response
          })
          .catch(error => console.error(error))

      }

    },



    inputPW() {

      fetch('https://sbbanalytics.ch/php/login.php?pw=' + this.password)
        .then(response => response.text())
        .then(data => {

          console.log(data);

          // if the password is true, log in to application
          if (data) {

            console.log('logging you in');

            localStorage.setItem('applicationPW', this.password);
            this.$store.commit('login');


          }


        })
        .catch(error => {

          console.error('There was an error!', error);

        });

    },

    initialPW() {

      if (localStorage.getItem('applicationPW') !== null) {

        let password = localStorage.getItem('applicationPW');


        fetch('https://sbbanalytics.ch/php/login.php?pw=' + password)
          .then(response => response.text())
          .then(data => {

            // if the password is true, log in to application
            if (data) {

              this.$store.commit('login');

            }

          })
          .catch(error => {

            console.error('There was an error!', error);

          });

      }

    }

  },

  computed: {

  },
  mounted() {

    this.initialPW();
    this.confirmMagicLink();

  }

}


</script>

<style scoped></style>
