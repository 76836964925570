<template>

  <password v-if="!this.$store.state.login"> </password>

  <div v-else>

    <navigation> </navigation>

    <div class="bg-white">

      <div class="w-1/2 bg-white mx-auto p-4 mt-8 rounded">

        <TitleWithImage class="mb-4" title="Backlog" image="newspaper-medium" />

        <p> Bitte erfasse Feature-Wünsche sowie Bugs als Nachricht im Analytics Teams-Channel: </p>

        <a class="text-red-500 underline" target="_blank"
          href="https://teams.microsoft.com/l/channel/19%3a2530054341684e59b3d22a58cf0032d0%40thread.skype/Analytics?groupId=12dc00e3-3374-4488-8a11-30bd5a9fb6f2&tenantId=2cda5d11-f0ac-46b3-967d-af1b2e1bd01a">
          Link zum Analytics-Team-Channel </a>

          <p class="mt-4">Der Backlog ist umgezogen und befindet sich neu ebenfalls auf Teams:</p>

          <a class="text-red-500 underline" target="_blank"
          href="https://teams.microsoft.com/l/entity/com.microsoft.teamspace.tab.planner/_djb2_msteams_prefix_3055727565?context=%7B%22subEntityId%22%3Anull%2C%22channelId%22%3A%2219%3A2530054341684e59b3d22a58cf0032d0%40thread.skype%22%7D&groupId=12dc00e3-3374-4488-8a11-30bd5a9fb6f2&tenantId=2cda5d11-f0ac-46b3-967d-af1b2e1bd01a&allowXTenantAccess=false">
          Link zum Backlog </a>

          <TitleWithImage class="mb-4 mt-8" title="FAQ" image="question-mark-medium" />

        <p> Antworten auf häufig gestellte Fragen findest du ebenfalls auf OneNote:</p>

          <a class="text-red-500 underline" target="_blank"
          href="https://sbb.sharepoint.com/sites/NewsroomSBB/_layouts/OneNote.aspx?id=%2Fsites%2FNewsroomSBB%2FSiteAssets%2FNotizbuch%20f%C3%BCr%20Newsroom%20SBB&wd=target%28AG%20Analytics.one%7C0A025A40-F762-40A2-AE1E-A2F5CD579CFF%2FFAQ%7C707FB055-0392-FE4B-9CAD-5466DDB00E27%2F%29
onenote:https://sbb.sharepoint.com/sites/NewsroomSBB/SiteAssets/Notizbuch%20für%20Newsroom%20SBB/AG%20Analytics.one#FAQ&section-id={0A025A40-F762-40A2-AE1E-A2F5CD579CFF}&page-id={707FB055-0392-FE4B-9CAD-5466DDB00E27}&end">
          Link zum OneNote </a>

      </div>

    </div>

  </div>

</template>

<script>
// @ is an alias to /src
import Password from '@/components/Password.vue'
import Navigation from '@/components/Navigation.vue'
import TitleWithImage from '@/components/TitleWithImage.vue'

export default {
  name: 'Impressum',
  components: {
    Password,
    Navigation,
    TitleWithImage
  },
  methods: {

    onload: function () {

      this.$store.dispatch('getData')

    },

  },

  computed: {

  },
  mounted: function () {

    this.onload();

  },
}
</script>
