<template>

  <div v-if="existCategories(typ)" class="flex ml-3">

    <img class="" :src="getIcon(icon)">

    <div class="flex h-16 text-center">

      <div class="ml-8 m-auto w-full flex flex-wrap ">

        <div
        class="text-center border-2 border-gray-200 bg-gray-200 m-1 p-2 "
        v-for="category in filteredCategories(typ)"
        >

        <p> {{category.Name}} </p>

      </div>

    </div>
  </div>

</div>

</template>

<script>
export default {
  name: 'ShowCategories',
  props: ['categories', 'typ', 'icon'],
  data() {
    return {

    }
  },
  methods: {

    existCategories(type){

      var filteredCategories = [];
      var that = this;

      if(that.categories){

        filteredCategories = that.categories.filter(function (kategorie) {
          return kategorie.Typ == type;
        });

      }

      if(filteredCategories.length > 0){

        return true;

      } else {

        return false;
      }

    },

    filteredCategories(type){

      var filteredCategories = [];
      var that = this;

      if(that.categories){

        filteredCategories = that.categories.filter(function (kategorie) {
          return kategorie.Typ == type;
        });

      }

      return filteredCategories;

    },

    getIcon(myicon){

      var images = require.context('@/assets/icons/', false, /\.svg/)
      return images('./' + myicon + ".svg")

    }

  },
  computed: {



  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
