<template>

  <div class="w-1/3 p-4">

    <label class="font-text text-2xl" :for="myinput"> {{myplaceholder}} </label><br>
    <input
    class="font-thin text-left mt-4 text-2xl w-full border-solid border-b-4 border-red-500 focus:outline-none"
    :id="myinput"
    @input="submit()"
    :placeholder="myplaceholder + ' eingeben'"
    v-model="myinput"
    type="text">

  </div>

</template>

<script>
export default {
  name: 'TextInput',
  emits: ['addInput'],
  props: ['myplaceholder'],
  data() {
    return {
      myinput: '',
    }
  },
  methods: {

    submit(){

      // console.log("Der Input ist: " + this.myinput);

      this.$emit('addInput', this.myinput);

    }

  },
  computed: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
