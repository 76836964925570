<template>

  <div class="">

    <p class="font-text" :for="myinput"> {{myplaceholder}} </p>

    <select
    class="font-thin text-left mt-4 text-xl w-full bg-white border-solid focus:outline-none"
    @input="submit($event)" name="item" :id="myinput" v-model="item">

    <option
    class="font-thin text-left mt-4 text-xl w-full border-solid border-b-4 border-red-500 focus:outline-none"
    v-for="item in items" :value="item">

    {{item}}

  </option>

</select>

</div>

</template>

<script>
export default {
  name: 'DropdownFilter',
  emits: ['addInput'],
  props: ['dbHandle', 'storeArray', 'myplaceholder', 'array'],
  data() {
    return {
      myinput: '',
      // item ist die ID des Standardwerts
      item: this.array[0],
    }
  },
  methods: {

    submit(id){

      // console.log("Der Input ist: " + event.target.value);

      this.$emit('addInput', event.target.value);

    },

    onload(){

      this.$emit('addInput', this.item);

    }

  },
  computed: {

    items(){

      return this.array;

    },

  },

  mounted: function(){

    this.onload();

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
