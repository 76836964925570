import { createStore } from 'vuex'

export default createStore({
  state: {

    login: false,

    data: false,

    // source ist der data-array, der nie manipuliert wird
    source: false,

    average_MAIN_DE: false,
    average_MAIN_FR: false,
    average_MAIN_IT: false,

    kategorien: false,
    positionen: false,
    kanaele: false,

    fetchComplete: false,
    untrackedFetchComplete: false,

    // hier wird das ampelsystem definiert
    ampelMain: {
      sehrgut: [7500, 1350, 260],
      gut: [4000, 640, 150],
      ok: [2400, 400, 90],
      untracked: [100, 25, 10]
    },
    ampelKunden: {
      sehrgut: [7500, 1350, 260],
      gut: [4000, 640, 150],
      ok: [2400, 400, 90],
      untracked: [200, 50, 20]
    },

    untrackedArticles: false,


  },

  getters: {


  },

  mutations: {

    filterData(state, payload){

      this.state.data = payload;

    },

    login(state){

      this.state.login = true;

    }

  },
  actions: {

    fetchPianoPerformance(state){

      fetch('https://sbbanalytics.ch/php/pianoPerformance.php')
      .then(response => response.text())
      .then(data => {

        // console.log("JOB: Habe Performance aktualisiert.")

      })
      .catch(error => {

        console.error('There was an error!', error);

      });

    },

    fetchUntrackedArticles(state){

      fetch('https://sbbanalytics.ch/php/updateStatus.php')
      .then(response => response.json())
      .then(data => {

        // console.log("JOB: Habe Status von alten Artikeln aktualisiert.")

      })
      .catch(error => {

        console.error('There was an error!', error);

      });

    },

    fetchPianoNewArticles(state){

      fetch('https://sbbanalytics.ch/php/pianoNewArticles.php')
      .then(response => response.text())
      .then(data => {

        // console.log('JOB: Habe neue Artikel aus Piano geladen.');

      })
      .catch(error => {

        console.error('There was an error!', error);

      });


    },

    fetchUntrackedArticles(state){

      fetch('https://sbbanalytics.ch/php/getUntracked.php')
      .then(response => response.json())
      .then(data => {

        this.state.untrackedArticles = data;
        this.state.untrackedFetchComplete = true;

      })
      .catch(error => {

        console.error('There was an error!', error);

      });

    },

    getData(state){

      // load all articles
      fetch('https://sbbanalytics.ch/php/getDatabase.php')
      .then(response => response.json())
      .then(data => {

        // console.log("I'm here")
        // console.log(data);

        this.state.data = data;

        this.state.source = data;

        this.state.fetchComplete = true;
        
        // console.log(data);
        // state.commit('sumVisitors');

      })
      .catch(error => {

        console.error('There was an error!', error);
      });


      // load categories
      fetch('https://sbbanalytics.ch/php/getKategorien.php')
      .then(response => response.json())
      .then(data => {

        this.state.kategorien = data;

        // console.log(this.state.kategorien);
        // state.commit('sumVisitors');

      })
      .catch(error => {

        console.log('There was an error!', error);
      });

      // load categories
      fetch('https://sbbanalytics.ch/php/getKanaele.php')
      .then(response => response.json())
      .then(data => {

        // console.log(data);

        this.state.kanaele = data;


        // state.commit('sumVisitors');

      })
      .catch(error => {

        console.error('There was an error!', error);
      });

      // load positionen
      fetch('https://sbbanalytics.ch/php/getPositionen.php')
      .then(response => response.json())
      .then(data => {

        this.state.positionen = data;

        // console.log(this.state.positionen);
        // state.commit('sumVisitors');

      })
      .catch(error => {

        console.error('There was an error!', error);
      });

    },
  },
  modules: {
  }
})
