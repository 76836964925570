<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container w-1/2">
          <div class="">
            <slot name="header">default header</slot>
          </div>

          <!-- START MODAL BODY -->
          <!-- START MODAL BODY -->
          <!-- START MODAL BODY -->
          <!-- START MODAL BODY -->

          <div class="mt-2">

            <div class="flex mb-8">

              <div class="w-1/2">

                <p>Von</p>

                <div class="w-full">

                  <datepicker
                  class="font-thin text-left mt-4 text-xl w-full border-red-500 focus:outline-none"
                  v-model="datum_von" />

                </div>

              </div>

              <div class="w-1/2">

                <p>Bis</p>

                <div class="w-full">

                  <datepicker
                  class="font-thin text-left mt-4 text-xl w-full border-red-500 focus:outline-none"
                  v-model="datum_bis" />

                </div>

              </div>


            </div>

            <!-- <div class="w-1/3">

              <DropdownFilter
              @addInput="writeInput($event, 'kanal')"
              dbHandle="Kanal"
              storeArray="kanaele"
              myplaceholder="Kanal"
              :array="['alle', 'main', 'kunden', 'beide']"
              > </DropdownFilter>

            </div> -->

          </div>

          <!-- ENDE MODAL BODY -->
          <!-- ENDE MODAL BODY -->
          <!-- ENDE MODAL BODY -->
          <!-- ENDE MODAL BODY -->

        <div class="modal-footer">
          <slot name="footer">
            <!-- default footer -->
            <button
            class="button-ux "
            @click="filter()"
            >OK</button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</Transition>
</template>

<script>
import DropdownFilter from '@/components/DropdownFilter.vue'
import Datepicker from 'vue3-datepicker'
import { ref } from 'vue'

export default {
  name: 'ModalFilterDate',
  props: ['show'],
  components: {
    DropdownFilter,
    Datepicker
  },
  data() {
    return {
      datum_bis: ref(new Date()),
      datum_von: ref(new Date()),

      kanal: '',

      activeFilter: false,

    }
  },
  methods: {

    writeInput(input, field){

      this[field] = input;
      // console.log(input);

    },

    setFromDate(){

      let d = new Date();
      d.setMonth(d.getMonth()-3);
      this.datum_von = d;

    },

    setToDate(){

      let d = new Date();
      this.datum_bis = d;

    },

    filter(){

      this.activeFilter = true;

      this.$emit('close');
      this.$emit('activeFilter', [this.datum_von, this.datum_bis]);
      this.$emit('filter', ['dateFilter', this.datum_von, this.datum_bis]);

    },

  },

  computed: {


  },

  mounted: function(){

    this.setFromDate();
    this.setToDate();

  },

}
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
* The following styles are auto-applied to elements with
* transition="modal" when their visibility is toggled
* by Vue.js.
*
* You can easily play with the modal transition by editing
* these styles.
*/

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
