<template>

  <div class="">

    <div class="">

      <table class="w-full  text-left">
        <thead>
          <tr>
            <th class="text-left p-2 border-white"></th>
            <th class="p-0 text-center font-normal border-white"> DE</th>
            <th class="p-0 text-center font-normal border-white"> FR</th>
            <th class="p-0 text-center font-normal border-white"> ITA</th>
          </tr>
        </thead>
        <tbody>

          <tr class="" v-if="kanal == 'main' || kanal =='beide' ">

            <td class="text-left border-white p-2">
              <!-- <img class="w-full" src="@/assets/icons/main.svg" alt=""> -->
              MAIN
            </td>
            <td :class="color(article.MAIN_DE, 'ampelMain', 'DE')" class="border-white p-0 px-2 text-center"> <input @input="collectInputs()" :class="color(article.MAIN_DE, 'ampelMain', 'DE')" class="w-full text-center" type="text" v-model="article.MAIN_DE"> </td>
            <td :class="color(article.MAIN_FR, 'ampelMain', 'FR')" class="border-white p-0 px-2 text-center"> <input @input="collectInputs()" :class="color(article.MAIN_FR, 'ampelMain', 'FR')" class="w-full text-center" type="text" v-model="article.MAIN_FR">  </td>
            <td :class="color(article.MAIN_IT, 'ampelMain', 'IT')" class="border-white p-0 px-2 text-center"> <input @input="collectInputs()" :class="color(article.MAIN_IT, 'ampelMain', 'IT')" class="w-full text-center" type="text" v-model="article.MAIN_IT">  </td>

          </tr>

          <tr class="" v-if="kanal == 'kunden' || kanal =='beide' ">
            <td class="text-left border-white p-2">
              <!-- <img src="@/assets/icons/king.svg" alt=""> -->
              Kunden
            </td>
            <td :class="color(article.KUNDEN_DE, 'ampelKunden', 'DE')" class="border-white p-0 px-2 text-center"> <input @input="collectInputs()" :class="color(article.KUNDEN_DE, 'ampelKunden', 'DE')" class="w-full text-center" type="text" v-model="article.KUNDEN_DE"> </td>
            <td :class="color(article.KUNDEN_FR, 'ampelKunden', 'FR')" class="border-white p-0 px-2 text-center"> <input @input="collectInputs()" :class="color(article.KUNDEN_FR, 'ampelKunden', 'FR')" class="w-full text-center" type="text" v-model="article.KUNDEN_FR"> </td>
            <td :class="color(article.KUNDEN_IT, 'ampelKunden', 'IT')" class="border-white p-0 px-2 text-center"> <input @input="collectInputs()" :class="color(article.KUNDEN_IT, 'ampelKunden', 'IT')" class="w-full text-center" type="text" v-model="article.KUNDEN_IT"> </td>

          </tr>

        </tbody>
      </table>

    </div>

  </div>


</template>

<script>

export default {
  name: 'PerformanceTable',
  emits: ['edit'],
  props: ['article', 'kanal'],
  data() {
    return {
      myinput: '',

    }
  },
  methods: {

    collectInputs(){

      // collect all edited fields to give to parent
      // in parent, update article by ID

      let edits = [this.article.MAIN_DE, this.article.MAIN_FR, this.article.MAIN_IT, this.article.KUNDEN_DE, this.article.KUNDEN_FR, this.article.KUNDEN_IT]

      this.$emit('edit', edits);

    },

    color(visitors, kanal, sprache) {


      if (sprache == 'DE'){

        if (visitors > this.$store.state[kanal].sehrgut[0]) { return 'bg-green-400' } else
        if (visitors > this.$store.state[kanal].gut[0]) { return 'bg-green-200' } else
        if (visitors > this.$store.state[kanal].ok[0]) { return 'bg-orange-200' } else
        if (visitors > 0) { return 'bg-red-200' } else
        { return 'bg-white' }

      } else if (sprache == 'FR'){

        if (visitors > this.$store.state[kanal].sehrgut[1]) { return 'bg-green-400' } else
        if (visitors > this.$store.state[kanal].gut[1]) { return 'bg-green-200' } else
        if (visitors > this.$store.state[kanal].ok[1]) { return 'bg-orange-200' } else
        if (visitors > 0) { return 'bg-red-200' } else
        { return 'bg-white' }

      } else if (sprache == 'IT'){

        if (visitors > this.$store.state[kanal].sehrgut[2]) { return 'bg-green-400' } else
        if (visitors > this.$store.state[kanal].gut[2]) { return 'bg-green-200' } else
        if (visitors > this.$store.state[kanal].ok[2]) { return 'bg-orange-200' } else
        if (visitors > 0) { return 'bg-red-200' } else
        { return 'bg-white' }

      }

    }

  },
  computed: {

    kanaele(){

      return this.$store.state.kanaele;

    },

    allArticles(){

      if(this.$store.state.data){

        return this.$store.state.data;

      } else {

        return 'loading...'
      }


    }

  },

  mounted: function(){

    this.collectInputs();

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
