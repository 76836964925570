<template>

  <div class="bg-blue-500 w-full">

    <h1>Hello! </h1>

  </div>

</template>

<script>
export default {
  name: 'ArticleDetails',
  emits: [],
  props: [],
  data() {
    return {

    }
  },
  methods: {

  },
  computed: {

  },

  mounted: function(){

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
