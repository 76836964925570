<template>

  <p class="text-gray-600"> Analytics-ID: {{ id }} </p>
  <h2 class="text-2xl mb-4"> Performance Analyse </h2>

  <p class="mb-4">

    In diesem Diagramm werden die kumulierten Visits im zeitlichen Verlauf angezeigt.
    Die Daten werden stündlich aktualisiert. <br> <br> <span class="text-red-500"> Experimentelles Feature: </span> 
    Neu wird die Performance Analyse 30 Tage lang erfasst. 
    Von der Schnittstelle zu Piano stehen Real-Time-Daten aber nur 7 Tage lang zur Verfügung. 
    Aus diesem Grund wird die Performance-Analyse von Tag 8 bis Tag 30 mit 24 Stunden Verzögerung eingetragen. 
    Manchmal scheinen deshalb die Visits kurzzeitig zu sinken. Das ist ein Fehler.

  </p>

  <p class=""> <b> Klicke auf die Legende, um Zielgruppen ein- und auszublenden! </b> </p>

  <p class="mb-4">  <a target="_blank" class="text-red-500 underline" href="https://www.dropbox.com/s/tsq3tbmnqasiiiw/SBB_Analytics_Tutorial_Performance_Analyse.mov?dl=0"> Link zum Tutorial </a></p>

  <p class="cursor-pointer">

    <span @click="showMAIN()" :class="{ 'text-red-500': this.chartMAIN }"> MAIN </span>

    |

    <span @click="showKunden()" :class="{ 'text-red-500': this.chartKunden }"> Kunden </span>

  </p>

  <div v-if="loaded" class="w-full mx-auto">

    <div class="w-full" v-if="(chartMAIN && !chartKunden)">
      <LineChart :myChartData="articleChartData" :myChartOptions="articleChartOptions"> </LineChart>
    </div>

    <div class="w-full" v-if="(chartKunden && !chartMAIN)">
      <LineChart :myChartData="articleChartDataKunden" :myChartOptions="articleChartOptions"> </LineChart>
    </div>

    <div class="w-full" v-if="(chartKunden && chartMAIN)">
      <LineChart :myChartData="articleChartDataMixed" :myChartOptions="articleChartOptions"> </LineChart>
    </div>

    <div class="w-full" v-if="(!chartKunden && !chartMAIN)">

      <p class="mt-4 text-red-500"> Bitte wähle eine Zeile oberhalb MAIN oder Kunden aus, um ein Diagramm anzuzeigen.
      </p>

    </div>

  </div>

  <div v-if="(id <= 602)">

    <p class="text-red mt-4"> Ungenügende Datenlage. Wahrscheinlich ist dieser Artikel zu alt, um Diagramme anzuzeigen. </p>

  </div>

</template>

<script>
import LineChart from '@/components/LineChart.vue'


export default {
  name: 'ChartContainer',
  components: {
    LineChart,
  },
  emits: [''],
  props: ['id'],
  data() {
    return {
      loaded: false,
      chartMAIN: true,
      chartKunden: false,
      articleChartOptions: {
        overrides: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              fontColor: 'blue',
              fontSize: 100,
              fontFamily: 'Arial'
            }
          }
        }
      },
      articleChartData: {
        labels: ['January', 'February', 'April'],
        datasets: [
          {
            data: [],
            label: 'MAIN DE',
            fill: false,
            backgroundColor: '#FDAE96',
            borderColor: '#FDAE96',
            tension: 0.1
          }, {

            data: [],
            label: 'MAIN FR',
            fill: false,
            backgroundColor: '#B1D8E0',
            borderColor: '#B1D8E0',
            tension: 0.1

          }, {

            data: [],
            label: 'MAIN IT',
            fill: false,
            backgroundColor: '#C5E3BF',
            borderColor: '#C5E3BF',
            tension: 0.1

          },
        ],

      },
      articleChartDataKunden: {
        labels: ['January', 'February', 'April'],
        datasets: [
          {

            data: [],
            label: 'KUNDEN DE',
            fill: false,
            backgroundColor: '#FF073A',
            borderColor: '#FF073A',
            tension: 0.1

          }, {

            data: [],
            label: 'KUNDEN FR',
            fill: false,
            backgroundColor: '#00FFFF',
            borderColor: '#00FFFF',
            tension: 0.1

          }, {

            data: [],
            label: 'KUNDEN IT',
            fill: false,
            backgroundColor: '#39FF14',
            borderColor: '#39FF14',
            tension: 0.1

          }
        ],

      },
      articleChartDataMixed: {
        labels: ['January', 'February', 'April'],
        datasets: [
          {
            data: [],
            label: 'MAIN DE',
            fill: false,
            backgroundColor: '#FDAE96',
            borderColor: '#FDAE96',
            tension: 0.1
          }, {

            data: [],
            label: 'MAIN FR',
            fill: false,
            backgroundColor: '#B1D8E0',
            borderColor: '#B1D8E0',
            tension: 0.1

          }, {

            data: [],
            label: 'MAIN IT',
            fill: false,
            backgroundColor: '#C5E3BF',
            borderColor: '#C5E3BF',
            tension: 0.1

          }, {

            data: [],
            label: 'KUNDEN DE',
            fill: false,
            backgroundColor: '#FF073A',
            borderColor: '#FF073A',
            tension: 0.1

          }, {

            data: [],
            label: 'KUNDEN FR',
            fill: false,
            backgroundColor: '#00FFFF',
            borderColor: '#00FFFF',
            tension: 0.1

          }, {

            data: [],
            label: 'KUNDEN IT',
            fill: false,
            backgroundColor: '#39FF14',
            borderColor: '#39FF14',
            tension: 0.1

          }
        ],

      },

    }
  },
  methods: {

    showMAIN() {

      this.chartMAIN = !this.chartMAIN;

    },

    showKunden() {

      this.chartKunden = !this.chartKunden;

    },

    fetchData() {

      // console.log('running');

      if (this.id > 550) {

        // console.log('too running');

        let formData = new FormData();
        formData.append('id', this.id);

        fetch("https://sbbanalytics.ch/php/getMesspunkte.php",
          {
            body: formData,
            method: "post"
          })

          .then(response => response.json())

          .then(data => {

            // console.log(data);

            this.makeArrays(data);

          })
          .catch(error => {

            console.error('There was an error!', error);

          });

      }

    },

    makeArrays(data) {

      // console.log(data);

      // map timestamp to array
      // map timestamp to array
      // map timestamp to array
      // map timestamp to array
      let timestamp = data.map(object => object.timestamp);

      // console.log(timestamp)

      // make readable date in timestamp array
      timestamp.forEach((element, index) => {

        // console.log(timestamp[index]);

        timestamp[index] = new Date(element).toLocaleString('de-CH', { weekday: 'short', month: 'short', day: 'numeric', hour: 'numeric' });

        // console.log(timestamp[index]);

      });

      // console.log(timestamp)

      timestamp = this.fitArray(timestamp);

      // show timestamp as labels
      this.articleChartData.labels = timestamp;
      this.articleChartDataKunden.labels = timestamp;
      this.articleChartDataMixed.labels = timestamp;


      // map visitors MAIN_DE to array
      // map visitors MAIN_DE to array
      // map visitors MAIN_DE to array
      // map visitors MAIN_DE to array

      let MAIN_DE = data.map(object => object.MAIN_DE);

      MAIN_DE = this.fitArray(MAIN_DE);

      this.articleChartData.datasets[0].data = MAIN_DE;
      this.articleChartDataMixed.datasets[0].data = MAIN_DE;

      console.log(this.articleChartData.datasets[0].data)

      // map visitors MAIN_FR to array
      // map visitors MAIN_FR to array
      // map visitors MAIN_FR to array
      // map visitors MAIN_FR to array

      let MAIN_FR = data.map(object => object.MAIN_FR);

      MAIN_FR = this.fitArray(MAIN_FR);

      this.articleChartData.datasets[1].data = MAIN_FR;
      this.articleChartDataMixed.datasets[1].data = MAIN_DE;


      // map visitors MAIN_IT to array
      // map visitors MAIN_IT to array
      // map visitors MAIN_IT to array
      // map visitors MAIN_IT to array

      let MAIN_IT = data.map(object => object.MAIN_IT);

      MAIN_IT = this.fitArray(MAIN_IT);

      this.articleChartData.datasets[2].data = MAIN_IT;
      this.articleChartDataMixed.datasets[2].data = MAIN_IT;

      // map visitors KUNDEN_DE to array
      // map visitors KUNDEN_DE to array
      // map visitors KUNDEN_DE to array
      // map visitors KUNDEN_DE to array

      let KUNDEN_DE = data.map(object => object.KUNDEN_DE);

      KUNDEN_DE = this.fitArray(KUNDEN_DE);

      this.articleChartDataKunden.datasets[0].data = KUNDEN_DE;
      this.articleChartDataMixed.datasets[3].data = KUNDEN_DE;

      // map visitors KUNDEN_FR to array
      // map visitors KUNDEN_FR to array
      // map visitors KUNDEN_FR to array
      // map visitors KUNDEN_FR to array

      let KUNDEN_FR = data.map(object => object.KUNDEN_FR);

      KUNDEN_FR = this.fitArray(KUNDEN_FR);

      this.articleChartDataKunden.datasets[1].data = KUNDEN_FR;
      this.articleChartDataMixed.datasets[4].data = KUNDEN_FR;

      // map visitors KUNDEN_IT to array
      // map visitors KUNDEN_IT to array
      // map visitors KUNDEN_IT to array
      // map visitors KUNDEN_IT to array

      let KUNDEN_IT = data.map(object => object.KUNDEN_IT);

      KUNDEN_IT = this.fitArray(KUNDEN_IT);

      this.articleChartDataKunden.datasets[2].data = KUNDEN_IT;
      this.articleChartDataMixed.datasets[5].data = KUNDEN_IT;




      this.loaded = true;

    },

    fitArray(oldArr) {

      // this function will shorten array to maxVal element and return it

      console.log(oldArr.length)

       if (oldArr.length > 25) {

        var arr = [];

        var maxVal = 25;

        var delta = Math.floor(oldArr.length / maxVal);

        for (let i = 0; i < oldArr.length;) {
          arr.push(oldArr[i]);
          i = i + delta;
        }

        return arr;

      } else {

        return oldArr;

      }

    }

  },
  computed: {

  },

  mounted() {

    this.fetchData();

  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
