<template>

  <div class="bg-white-500 p-8">

    <h1 class="mb-8 p-4 text-red-500">Neuen Artikel erfassen</h1>

    <h2 class="p-4 mt-16" >📝 Metadaten</h2>

    <TextInput
    @addInput="writeInput($event, 'titel')"
    myplaceholder="Titel"
    class="w-full"
    > </TextInput>

    <TextInput
    @addInput="writeInput($event, 'url')"
    myplaceholder="URL"
    class="w-full"
    > </TextInput>

    <div class="flex">

      <div class="w-1/2 p-4">

        <label class="font-text text-2xl" for="datepicker">Datum</label> <br>
        <datepicker
        id="datepicker"
        class="font-thin text-left mt-4 text-2xl w-full border-solid border-b-4 border-red-500 focus:outline-none"
        v-model="datum" />
      </div>

      <!-- <DropdownInput
      @addInput="writeInput($event, 'kanal')"
      dbHandle="Kanal"
      storeArray="kanaele"
      myplaceholder="Kanal"
      > </DropdownInput>

      <DropdownInput
      @addInput="writeInput($event, 'position')"
      dbHandle="Position"
      storeArray="positionen"
      myplaceholder="Position"
      > </DropdownInput> -->

    </div>

    <!-- <h2 class="p-4 mt-16" >🧮 Zahlen</h2>

    <div v-if="this.kanal == 1 || this.kanal == 3" class="main flex">

      <NumberInput
      @addInput="writeInput($event, 'MAIN_DE')"
      myplaceholder="Main DE"
      > </NumberInput>

      <NumberInput
      @addInput="writeInput($event, 'MAIN_FR')"
      myplaceholder="Main FR"
      > </NumberInput>

      <NumberInput
      @addInput="writeInput($event, 'MAIN_IT')"
      myplaceholder="Main IT"
      > </NumberInput>

    </div> -->

    <!-- <div v-if="this.kanal == 2 || this.kanal == 3" class="kunden flex">

      <NumberInput
      @addInput="writeInput($event, 'KUNDEN_DE')"
      myplaceholder="Kunden DE"
      > </NumberInput>

      <NumberInput
      @addInput="writeInput($event, 'KUNDEN_FR')"
      myplaceholder="Kunden FR"
      > </NumberInput>

      <NumberInput
      @addInput="writeInput($event, 'KUNDEN_IT')"
      myplaceholder="Kunden IT"
      > </NumberInput>

    </div> -->

    <h2 class="p-4 mt-16 mb-0"> 📈 Promotion</h2>

    <CategoryInput
    @addInput="writeCategory($event)"
    type="Promo"
    :selectedCategories="this.categories"
    color="red"
    > </CategoryInput>

    <!-- <h2 class="p-4 mt-16" >👏 Community</h2>

    <div class="flex">

      <NumberInput
      @addInput="writeInput($event, 'likes')"
      myplaceholder="Likes"
      > </NumberInput>

      <NumberInput
      @addInput="writeInput($event, 'dislikes')"
      myplaceholder="Dislikes"
      > </NumberInput>

      <NumberInput
      @addInput="writeInput($event, 'kommentare')"
      myplaceholder="Kommentare"
      > </NumberInput>


    </div> -->

    <h2 class="p-4 mt-8 ">📚 Kategorien</h2>

    <!-- <h2 class="p-4 mt-16">📜 Umsetzungsform</h2> -->

    <CategoryInput
    @addInput="writeCategory($event)"
    type="Umsetzungsform"
    :selectedCategories="this.categories"
    color="red"
    titel="Textform"
    > </CategoryInput>

    <!-- <h2 class="p-4 mt-16"> </h2> -->

    <CategoryInput
    @addInput="writeCategory($event)"
    type="Multimedia"
    :selectedCategories="this.categories"
    color="red"
    titel="Multimedia"
    > </CategoryInput>

    <!-- <h2 class="p-4 mt-16">🎯 Strategische Schwerpunkte</h2> -->

    <CategoryInput
    @addInput="writeCategory($event)"
    type="Thema"
    :selectedCategories="this.categories"
    color="red"
    titel="Themen"
    > </CategoryInput>


    <CategoryInput
    @addInput="writeCategory($event)"
    type="Schwerpunkt"
    :selectedCategories="this.categories"
    color="red"
    titel="Strategische Schwerpunkte"
    > </CategoryInput>

    <h2 class="p-4 mt-16" > ✍️ Notizen</h2>

    <TextInput
    @addInput="writeInput($event, 'notizen')"
    myplaceholder="Notizen"
    class="w-full"
    > </TextInput>

    <button
    @click="sendForm()" class="text-left mt-8 bg-red-300 hover:bg-red-500 text-white text-xl font-bold py-4 px-8 rounded">
    Eintragen
  </button>

  <p class="mt-4" v-if="message"> {{message}} <span class="underline text-red-500 cursor-pointer" @click="emptyForm()"> Neuer Artikel erfassen ... </span> </p>


</div>

</template>

<script>
import TextInput from '@/components/TextInput.vue'
import NumberInput from '@/components/NumberInput.vue'
import CategoryInput from '@/components/CategoryInput.vue'
import DropdownInput from '@/components/DropdownInput.vue'

import Datepicker from 'vue3-datepicker'
import { ref } from 'vue'

export default {
  name: 'InsertFormular',
  components: {
    TextInput,
    NumberInput,
    Datepicker,
    CategoryInput,
    DropdownInput
  },
  props: [],
  data() {
    return {
      titel: '',
      url: '',
      // MAIN_DE: '',
      // MAIN_FR: '',
      // MAIN_IT: '',
      // KUNDEN_DE: '',
      // KUNDEN_FR: '',
      // KUNDEN_IT: '',
      // likes: '',
      // dislikes: '',
      // kommentare: '',
      // kanal: '',
      // position: '',
      notizen: '',
      categories: [],
      datum: ref(new Date()),
      message: false,

    }
  },
  methods: {

    writeInput(input, field){

      this[field] = input;

    },

    writeCategory(category){

      if(this.categories.indexOf(category) == -1){

        this.categories.push(category);

      } else {

        this.categories.splice(this.categories.indexOf(category), 1);

      }

      // console.log(this.categories);

    },

    emptyForm(){

      location.reload();

      // this.titel = '';
      // this.url = '';
      // this.MAIN_DE = '';
      // this.MAIN_FR = '';
      // this.MAIN_IT = '';
      // this.KUNDEN_DE = '';
      // this.KUNDEN_FR = '';
      // this.KUNDEN_IT = '';
      // this.likes = '';
      // this.dislikes = '';
      // this.kommentare = '';
      // this.kanal = '';
      // this.position = '';
      // this.notizen = '';
      // this.categories = [];
      // this.message = false;

    },

    sendForm(state, status){

      // convert date to string

      var d = this.datum
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      let year = d.getFullYear();

      if (month.length < 2)
      month = '0' + month;
      if (day.length < 2)
      day = '0' + day;

      let datum = [year, month, day].join('-');

      // Build formData object.
      let formData = new FormData();
      formData.append('datum', datum);
      formData.append('titel', this.titel);
      formData.append('url', this.url);
      // formData.append('MAIN_DE', this.MAIN_DE);
      // formData.append('MAIN_FR', this.MAIN_FR);
      // formData.append('MAIN_IT', this.MAIN_IT);
      // formData.append('KUNDEN_DE', this.KUNDEN_DE);
      // formData.append('KUNDEN_FR', this.KUNDEN_FR);
      // formData.append('KUNDEN_IT', this.KUNDEN_IT);
      // formData.append('likes', this.likes);
      // formData.append('dislikes', this.dislikes);
      // formData.append('kommentare', this.kommentare);
      // formData.append('position', this.position);
      // formData.append('kanal', this.kanal);
      formData.append('notizen', this.notizen);
      formData.append('kategorien', JSON.stringify(this.categories));

      fetch("https://sbbanalytics.ch/php/insertArtikel.php",
      {
        body: formData,
        method: "post"
      })
      .then(response => response.text())
      .then( response => {

        // console.log(response);
        this.message = response;

        this.$store.dispatch('fetchPianoPerformance');

      })
      .catch(function (response) {
        //handle error
        alert("Uh! Da ist etwas schief gelaufen")
        // console.log(response)
      });

    },

  },
  computed: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
