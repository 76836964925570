<template>

  <!-- <p class="mt-4"> Für folgende Artikel wurden noch keine Zahlen gezogen:</p> -->

  <div class="w-full">

    <!-- :class="color(article.Datum)" -->

    <div v-for="article in allArticles" class=" flex">

      <div v-if="!editMode || article.ID == editingArticle" class="w-1/2 p-4 m-4 mb-4 border-2 border-gray-400 rounded">
        <!-- Datum -->
        <!-- Datum -->
        <!-- Datum -->
        <!-- Datum -->

        <TransformDate v-if="!editMode" :date="article.Datum" />

        <datepicker v-if="editMode" id="datepicker"
          class="font-thin text-left mt-4 mb-2 text-2xl w-full border-solid focus:outline-none" v-model="datum" />

        <!-- Datum Ende -->
        <!-- Datum Ende -->
        <!-- Datum Ende -->
        <!-- Datum Ende -->


        <!-- Titel -->
        <!-- Titel -->
        <!-- Titel -->
        <!-- Titel -->

        <a v-if="!editMode" class=" hover:underline text-2xl text-red-500 cursor-pointer" target="_blank"
          :href="article.URL"> {{
              article.Titel
          }} </a>

        <input v-if="editMode"
          class="placeholder:text-2xl placeholder:text-red-500 font-thin text-left mt-4 text-bold text-2xl w-full text-red-500 focus:outline-none"
          :placeholder="article.Titel" v-model="titel" type="text">

        <!-- Titel Ende-->
        <!-- Titel Ende-->
        <!-- Titel Ende-->
        <!-- Titel Ende-->

        <div class="flex justify-start gap-14 mt-4">

          <div class="w-2/3">

            <PerformanceTable v-if="!editMode" :article="article" :kanal="article.Kanal" />


            <PerformanceTableEdit v-if="editMode && currentlyEditing == article.ID" :article="article"
              :kanal="article.Kanal" @edit="editPerformance($event)" />

          </div>

          <div class="w-1/3">

            <!-- <button @click="showDiagrams(article.ID)" class="mt-6 button-ux" :class="styleButton(article.ID, currentDiagram)">
              Performance Analyse
            </button> -->


          </div>

        </div>

        <div class="ml-4">

          <div class="flex mt-16 w-full">

            <img class="text-left" :src="require('@/assets/icons/speech-bubble-medium.svg')" alt="">

            <div class="flex h-full ml-8 ">
              <div class="w-full text-left">

                <p v-if="!editMode && this.$store.state.fetchComplete" class="text-left">
                  <span v-if="article.Notizen.length == 0" class="">

                    <i>Keine Notizen.</i>

                  </span>

                  {{ article.Notizen }}

                </p>

                <input v-if="editMode" class="bg-gray-100 text-left mt-4 w-full focus:outline-none" v-model="notizen"
                  placeholder="Hier Notizen eingeben ..." type="text">

              </div>
            </div>

          </div>

        </div>

        <div class="flex justify-start gap-14 mt-4">

        </div>

        <div class="">

          <ShowCategories class="mt-6" :categories="article.Kategorien" typ="Promo" icon="promo" />

          <ShowCategories :categories="article.Kategorien" typ="Umsetzungsform" icon="pen" />

          <ShowCategories :categories="article.Kategorien" typ="Multimedia" icon="camera" />

          <ShowCategories :categories="article.Kategorien" typ="Themen" icon="topic" />

          <ShowCategories :categories="article.Kategorien" typ="Schwerpunkt" icon="target" />

        </div>


        <div v-if="editCategories && currentlyEditing == article.ID" class="">

          <CategoryInput @addInput="writeCategory($event)" type="Promo" :selectedCategories="this.categories"
            color="red" titel="Promo"> </CategoryInput>

          <p v-if="!viewAllCategorys" @click="viewAllCategorys = true"
            class="cursor-pointer underline text-red ml-4 mt-4">Alle Kategorien anzeigen ...</p>

          <div v-if="viewAllCategorys" class="">

            <CategoryInput @addInput="writeCategory($event)" type="Umsetzungsform" :selectedCategories="this.categories"
              color="red" titel="Textform"> </CategoryInput>

            <CategoryInput @addInput="writeCategory($event)" type="Multimedia" :selectedCategories="this.categories"
              color="red" titel="Multimedia"> </CategoryInput>


            <CategoryInput @addInput="writeCategory($event)" type="Thema" :selectedCategories="this.categories"
              color="red" titel="Themen"> </CategoryInput>


            <CategoryInput @addInput="writeCategory($event)" type="Schwerpunkt" :selectedCategories="this.categories"
              color="red" titel="Strategische Schwerpunkte"> </CategoryInput>

          </div>

        </div>

        <div class="text-right ">

          <!-- button logic -->

          <!-- <button v-if="!editCategories && !editMode" @click="editCategory(article)" class="mt-6 button-ux"> Kategorien
        </button>
        <button v-if="editCategories && currentlyEditing == article.ID" @click="submitCategory(article.ID)"
          class="mt-6 button-ux"> Speichern </button> -->

          <!-- Delete Button -->
          <!-- Delete Button -->
          <!-- Delete Button -->

          <button v-if="!askDelete && editMode && currentlyEditing == article.ID" @click="modalDelete(article.ID)"
            class="mr-4 cursor-pointer">Entfernen</button>

          <button v-if="askDelete && editMode && currentlyEditing == article.ID" @click="" class="mr-4 cursor-pointer">
            Möchtest du den Artikel wirklich aus dem Tracking entfernen? </button>

          <button v-if="askDelete && editMode && currentlyEditing == article.ID" @click="deleteArticle(article.ID)"
            class="mr-4 cursor-pointer"> Ja </button>

          <button v-if="askDelete && editMode && currentlyEditing == article.ID" @click="modalDelete()"
            class="mr-4 cursor-pointer text-red-500"> Nein </button>

          <!-- Delete Button Ende -->
          <!-- Delete Button Ende -->
          <!-- Delete Button Ende -->

          <!-- little spinning arrow -->
          <!-- little spinning arrow -->


          <div class="ml-4 flex w-full">

            <div class="w-3/4">

              <div class="w-full ml-16 text-left">
                <button v-if="!editMode && !editCategories" @click="edit(article.ID, article)"
                  class="mt-6 button-ux align-bottom">
                  Bearbeiten
                </button>

                <button v-if="editMode && currentlyEditing == article.ID" @click="submit(article.ID)"
                  class="mt-6 button-ux align-bottom	">
                  Speichern </button>

                <button @click="showDiagrams(article.ID)" class="mt-6 button-ux"
                  :class="styleButton(article.ID, currentDiagram)">
                  Performance Analyse
                </button>

              </div>


              <div v-if="!article.Status" class="flex mt-16 w-full">

                <img class="rotateIt text-left" :src="require('@/assets/icons/arrows-circle-medium.svg')" alt="">

                <div class="flex h-full ml-8">
                  <div class="m-auto">

                    <p class="mt-1 text-left"> <em>Performance aktualisiert am: <br>
                        {{ readableDate(article.Performance_Updated) }} </em></p>

                  </div>
                </div>

              </div>

              <div v-else class="flex mt-16">

                <img class="text-left" :src="require('@/assets/icons/circle-tick-medium.svg')" alt="">

                <div class="flex h-full ml-8">
                  <div class="m-auto">

                    <p class="mt-1 text-left"> <em>Erhebung der Performance abgeschlossen. <br> Zuletzt aktualisiert am:
                        {{ readableDateShort(article.Performance_Updated) }} </em> </p>

                  </div>
                </div>

              </div>

            </div>

            <!-- ENDE little spinning arrow -->
            <!-- ENDE little spinning arrow -->
            <!-- ENDE little spinning arrow -->

            <div class="w-1/4">


            </div>

          </div>
        </div>

      </div>

      <div class="w-1/2 p-4 m-4 mb-4  rounded" :class="(styleBorder(article.ID, currentDiagram))">

        <div v-if="(this.$store.state.fetchComplete && currentDiagram == article.ID)">

          <ChartContainer :id="article.ID">

          </ChartContainer>

        </div>


      </div>

    </div>

  </div>
</template>

<script>
import PerformanceTable from '@/components/PerformanceTable.vue'
import PerformanceTableEdit from '@/components/PerformanceTableEdit.vue'
// import PerformanceAmpel from '@/components/PerformanceAmpel.vue'
import TransformDate from '@/components/TransformDate.vue'
import SocialMeter from '@/components/SocialMeter.vue'
import SocialMeterEdit from '@/components/SocialMeterEdit.vue'
import ShowCategories from '@/components/ShowCategories.vue'
import CategoryInput from '@/components/CategoryInput.vue'

import ChartContainer from '@/components/ChartContainer.vue'

import Datepicker from 'vue3-datepicker'
import { ref } from 'vue'

export default {
  name: 'NewArticleList',
  components: {
    PerformanceTable,
    PerformanceTableEdit,
    // PerformanceAmpel,
    TransformDate,
    SocialMeter,
    ShowCategories,
    SocialMeterEdit,
    CategoryInput,
    Datepicker,
    ChartContainer
  },
  emits: ['edit'],
  props: [],
  data() {
    return {
      myinput: '',
      editMode: false,
      editCategories: false,
      viewAllCategorys: false,
      articlePerformance: [],
      articleSocials: [],
      categories: [],
      currentlyEditing: 0,
      askDelete: false,
      datum: "",
      titel: "",
      editingArticle: 0,
      notizen: "",

      currentDiagram: 0,
    }
  },
  methods: {

    deleteArticle(id) {

      // console.log("Deleting Article " + id);


      let formData = new FormData();
      formData.append('ID', id);

      fetch("https://sbbanalytics.ch/php/deleteArticle.php",
        {
          body: formData,
          method: "post"
        })
        .then((res) => {

          return res.text();

        })
        .then(response => {

          // console.log(response);

          this.$store.dispatch('getData');

          window.location.reload();

        })
        .catch(function (response) {
          //handle error
          alert("Uh! Da ist etwas schief gelaufen")
          // console.log(response)
        });

    },

    modalDelete() {

      this.askDelete = !this.askDelete;

    },

    submit(id) {

      this.submitCategory(id);

      var datum = this.makeDate();
      // console.log(datum);

      this.editMode = false;
      this.currentlyEditing = 0;

      // console.log('submitting!!')

      // go to db and update this damned article!

      // Build formData object.
      let formData = new FormData();
      formData.append('ID', id);
      formData.append('MAIN_DE', this.articlePerformance[0]);
      formData.append('MAIN_FR', this.articlePerformance[1]);
      formData.append('MAIN_IT', this.articlePerformance[2]);
      formData.append('KUNDEN_DE', this.articlePerformance[3]);
      formData.append('KUNDEN_FR', this.articlePerformance[4]);
      formData.append('KUNDEN_IT', this.articlePerformance[5]);

      formData.append('likes', this.articleSocials[0]);
      formData.append('dislikes', this.articleSocials[1]);
      formData.append('kommentare', this.articleSocials[2]);
      formData.append('notizen', this.notizen);
      formData.append('status', 0);

      formData.append('datum', datum);
      formData.append('titel', this.titel);

      fetch("https://sbbanalytics.ch/php/updateArticle.php",
        {
          body: formData,
          method: "post"
        })
        .then(response => {

          // console.log(response);

          this.$store.dispatch('getData');

          this.datum = "";
          this.titel = "";



        })
        .catch(function (response) {
          //handle error
          alert("Uhhjeaaahh! Da ist etwas schief gelaufen")
          // console.log(response)
        });


    },

    edit(id, article) {

      // console.log("Start Edit Article")

      this.datum = new Date(article.Datum);
      this.titel = article.Titel;
      this.editingArticle = article.ID;
      this.notizen = article.Notizen;

      // console.log(this.editingArticle);

      this.editCategory(article);

      this.editMode = true;

      this.currentlyEditing = id;
      // console.log(this.currentlyEditing);

    },

    editCategory(article) {

      // console.log("Start Edit Categories")

      this.editCategories = true;
      // this.currentlyEditing = article.ID;

      for (var i = 0; i < article.Kategorien.length; i++) {

        this.categories.push(article.Kategorien[i].ID);

        // console.log("Aktuelle Kategorien: " + this.categories);

      }

    },

    makeDate() {

      var d = this.datum
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      let year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');

    },

    submitCategory(id) {

      this.editCategories = false;

      let formData = new FormData();
      formData.append('ID', id);
      formData.append('kategorien', JSON.stringify(this.categories));

      // console.log("Pushe folgende Kategorien: " + this.categories);


      fetch("https://sbbanalytics.ch/php/updateKategorien.php",
        {
          body: formData,
          method: "post"
        })
        .then(response => {

          console.log(response);

          this.categories = [];
          // window.location.reload();

          // this.message = true;

          this.$store.dispatch('getData');


        })
        .catch(function (response) {
          //handle error
          alert("Uh! Da ist etwas schief gelaufen")
          // console.log(response)
        });

    },

    writeCategory(category) {

      if (this.categories.indexOf(category) == -1) {

        this.categories.push(category);

        // console.log("Writing Category: " + category + " in Array ");

      } else {

        this.categories.splice(this.categories.indexOf(category), 1);

      }

      // console.log(this.categories);

    },

    editPerformance(edits) {

      this.articlePerformance = edits;

      // console.log(this.articlePerformance);

    },

    editSocials(edits) {

      this.articleSocials = edits;

      // console.log(this.articleSocials);

    },

    color(datum) {

      if (new Date(datum).getTime() + 1209600000 < new Date().getTime()) {

        return 'border-red-900';

      }

    },

    sortDate(a, b) {

      if (new Date(a.Datum).getTime() < new Date(b.Datum).getTime()) {
        return -1;
      }
      if (new Date(a.Datum).getTime() > new Date(b.Datum).getTime()) {
        return 1;
      }
      // a muss gleich b sein
      return 0;
    },

    readableDate(date) {

      if (date != undefined) {

        date = new Date(date);

        date = date.toLocaleString('de-CH', { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' });

        // console.log(date);

      }

      return date;

    },

    readableDateShort(date) {

      if (date != undefined) {

        date = new Date(date);

        date = date.toLocaleString('de-CH', { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' });

        // console.log(date);

      }

      return date;

    },

    showDiagrams(id) {

      if (this.currentDiagram == id) {

        this.currentDiagram = 0;

      } else {

        this.currentDiagram = id;

      }


    },

    styleBorder(id, currentDiagram) {

      if (id == currentDiagram) {

        return 'border-2 border-gray-400';

      } else {

        return '';
      }


    },

    styleButton(id, currentDiagram) {

      if (id == currentDiagram) {

        return 'bg-red-300 text-white';

      } else {

        return '';
      }


    }


  },
  computed: {

    allArticles() {

      if (this.$store.state.data) {

        // filter articles
        // filter articles
        // filter articles

        return this.$store.state.data;


      } else {

        return 'loading...'
      }


    },

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.rotateIt {
  animation: rotation 10s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
</style>
