<template>

  <password v-if="!this.$store.state.login"> </password>

  <div v-else class="">

    <Navigation />

    <div class="bg-white">

      <div class="w-1/2 bg-white mx-auto p-4 mt-8 rounded">

        <TitleWithImage class="mb-4" title="Publishing Assistent" image="robot-medium" />

        <p class="">
          Der Publishing-Assistent hilft dir dabei, kürzlich veröffentlichte Artikel SBB Analytics hinzuzufügen. <br>
          Klicke auf einen Artikel in der Liste, um zu beginnen.
        </p>

      </div>

      <div class="w-1/2 bg-white mx-auto p-4">

        <PublishingFormular />

        <div class="mt-8">

          <ul class="list-disc">

            <li> <span class="text-red-500"> Update-Artikel </span>, wie beispielsweise <strong> VD-Aktuell </strong>, Update-Corona oder
              Update-Energie können aus technischen Gründen leider nicht automatisch aufgelistet werden. </li>

            <li> <span class="text-red-500"> Einsprachige Artikel </span>, die <strong> nur französische </strong> und / oder <strong> italienische </strong>
              Seiten haben, müssen aus technischen Gründen ebenfalls manuell erfasst werden. </li>

          </ul>

          <p class="mt-4"> Die betreffenden Artikel können <router-link class="underline cursor-pointer text-red-500" to="/erfassen">
              hier</router-link>
            manuell erfasst und ins Tracking aufgenommen werden.</p>

        </div>

      </div>



    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import PublishingFormular from '@/components/PublishingFormular.vue'
import Navigation from '@/components/Navigation.vue'
import Password from '@/components/Password.vue'
import TitleWithImage from '@/components/TitleWithImage.vue'

export default {
  name: 'InsertView',
  components: {
    PublishingFormular,
    Navigation,
    Password,
    TitleWithImage
  },
  methods: {

    onload: function () {

      this.$store.dispatch('getData')

    },

  },
  mounted: function () {

    this.onload();

  },
}
</script>
