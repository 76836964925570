<template>

  <div class="">

    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="relative z-10">

      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">

          <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-1/2">

            <div class="h-medium px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

              <!-- CONTENT ANFANG, FILTER IN NEUE KOMPONENTE AUSLAGERN -->
              <!-- CONTENT ANFANG, FILTER IN NEUE KOMPONENTE AUSLAGERN -->
              <!-- CONTENT ANFANG, FILTER IN NEUE KOMPONENTE AUSLAGERN -->
              <!-- CONTENT ANFANG, FILTER IN NEUE KOMPONENTE AUSLAGERN -->
              <!-- CONTENT ANFANG, FILTER IN NEUE KOMPONENTE AUSLAGERN -->

              <!-- Event Bus muss noch erstellt werden -->

              <div class="filter">

              <div class="sm:flex sm:items-start">

                <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">

                  <img class="text-left -ml-3" src="@/assets/icons/filter-medium.svg" alt="">

                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">

                  <div class="">


                    <h3> Filter setzen</h3>

                    <div class="mt-2">

                      <div class="flex mb-8">

                        <div class="w-1/2">

                          <p>Von</p>

                          <div class="w-full">

                            <datepicker
                            class="font-thin text-left mt-4 text-xl w-full border-red-500 focus:outline-none"
                            v-model="datum_von" />

                          </div>

                        </div>

                        <div class="w-1/2">

                          <p>Bis</p>

                          <div class="w-full">

                            <datepicker
                            class="font-thin text-left mt-4 text-xl w-full border-red-500 focus:outline-none"
                            v-model="datum_bis" />

                          </div>

                        </div>


                      </div>

                      <div class="w-1/3">

                        <DropdownFilter
                        @addInput="writeInput($event, 'kanal')"
                        dbHandle="Kanal"
                        storeArray="kanaele"
                        myplaceholder="Kanal"
                        :array="['alle', 'main', 'kunden', 'beide']"
                        > </DropdownFilter>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-white px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button @click="filter()" class="button-ux"> Filtern </button>
              </div>
            </div>

          </div>

            <!-- CONTENT ENDE, FILTER IN NEUE KOMPONENTE AUSLAGERN -->
            <!-- CONTENT ENDE, FILTER IN NEUE KOMPONENTE AUSLAGERN -->
            <!-- CONTENT ENDE, FILTER IN NEUE KOMPONENTE AUSLAGERN -->
            <!-- CONTENT ENDE, FILTER IN NEUE KOMPONENTE AUSLAGERN -->
            <!-- CONTENT ENDE, FILTER IN NEUE KOMPONENTE AUSLAGERN -->

          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Datepicker from 'vue3-datepicker'
import { ref } from 'vue'
import DropdownFilter from '@/components/DropdownFilter.vue'
import TitleWithImage from '@/components/TitleWithImage.vue'


export default {
  name: 'ModalFilter',
  emits: ['closeModal', 'activeFilter'],
  props: [],
  components: {
    Datepicker,
    DropdownFilter,
    TitleWithImage
  },
  data() {
    return {
      datum_bis: ref(new Date()),
      datum_von: ref(new Date()),

      kanal: '',

      activeFilter: false,

    }
  },
  methods: {

    cancel(){

      console.log('cancelme')

    },

    writeInput(input, field){

      this[field] = input;
      // console.log(input);

    },

    setFromDate(){

      let d = new Date();
      d.setMonth(d.getMonth()-3);
      this.datum_von = d;

    },

    setToDate(){

      let d = new Date();
      this.datum_bis = d;

    },

    filter(){

      // FilterFromDate needs to be First in the Row! Because there we tap the source
      this.filterFromDate();
      this.filterToDate();

      if (this.kanal != 'alle'){

        this.filterChannel();

      }

      this.activeFilter = true;

      this.$emit('closeModal');
      this.$emit('activeFilter', [this.datum_von, this.datum_bis]);

    },

    filterFromDate(){

      let data = this.$store.state.source;

      var that = this;

      let filtered = data.filter(function (article) {
        return new Date(article.Datum).getTime() > that.datum_von.getTime();
      });

      console.log(filtered);

      this.$store.commit('filterData', filtered);

    },

    filterToDate(){

      let data = this.$store.state.data;

      var that = this;

      let filtered = data.filter(function (article) {
        return new Date(article.Datum).getTime() < that.datum_bis.getTime();
      });

      console.log(filtered);

      this.$store.commit('filterData', filtered);

    },

    filterChannel(){

      let data = this.$store.state.data;

      var that = this;

      let filtered = data.filter(function (article) {
        return article.Kanal == that.kanal;
      });

      console.log(filtered);

      this.$store.commit('filterData', filtered);

    },


  },
  computed: {


  },

  mounted: function(){

    this.setFromDate();
    this.setToDate();

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
