<template>
  <!-- <nav class="m-4 text-center">
    <router-link to="/erfassen">Erfassen</router-link> |
    <router-link to="/alle">Anzeigen</router-link>
  </nav> -->
  <router-view/>
</template>

<style>

</style>
