<template>

  <!-- <p>Differenz: {{difference(likes, dislikes)}}</p> -->

  <div class="flex">

    <img class="inline" src="@/assets/icons/thumb_up.svg">
    <input @input="collectInputs()" class="w-1/3" v-model="likes">

  </div>

  <div class="flex">

    <img class="inline" src="@/assets/icons/thumb_down.svg">
    <input @input="collectInputs()" class="w-1/3" v-model="dislikes">

  </div>

  <div class="flex">

    <img class="inline" src="@/assets/icons/comment.svg">
    <input @input="collectInputs()" class="w-1/3" v-model="comments">

  </div>

</template>

<script>
export default {
  name: 'SocialMeter',
  emits: ['edit'],
  props: ['likes', 'dislikes', 'comments'],
  data() {
    return {

    }
  },
  methods: {

    collectInputs(){

      // collect all edited fields to give to parent
      // in parent, update article by ID

      let edits = [this.likes, this.dislikes, this.comments]

      this.$emit('edit', edits);

    },

  },

  computed: {

  },

  mounted: function(){

    this.collectInputs();

  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
