<template>

  <password v-if="!this.$store.state.login"> </password>

  <div v-else class="">

    <Navigation/>

    <div class="bg-white">

      <div class="w-1/2 bg-white mx-auto p-4 mt-8 rounded border-gray-300 border-2">

        <InsertFormular/>

      </div>

    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import InsertFormular from '@/components/InsertFormular.vue'
import Navigation from '@/components/Navigation.vue'
import Password from '@/components/Password.vue'

export default {
  name: 'InsertView',
  components: {
    InsertFormular,
    Navigation,
    Password
  },
  methods: {

    onload: function () {

      this.$store.dispatch('getData');
      // this.$store.dispatch('fetchPianoPerformance');
      // this.$store.dispatch('fetchPianoNewArticles');

    },

  },
  mounted: function(){

    this.onload();

  },
}
</script>
