<template>

  <password v-if="!this.$store.state.login"> </password>

  <div v-else>

    <Navigation/>

    <div class="bg-white">

      <div class="w-1/2 bg-white mx-auto p-4 mt-8 rounded">

        <TitleWithImage
        class="mb-4"
        title="Zahlen-Ziehen"
        image="chart-column-medium"/>

        <p class="">
          Nach 14 Tagen wird die Performance von publizierten Artikeln hier in SBB Analytics eingetragen.
        </p>

      </div>

      <div class="w-1/2 bg-white mx-auto p-4 rounded">

        <!-- <ZahlenZiehenFilters
        /> -->

        <NewArticleList
        @edit="showEditFields($event)"/>

        <!-- <UpdateFormular
        ref="updateFormular"
        /> -->

      </div>

    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import NewArticleList from '@/components/NewArticleList.vue'
import Navigation from '@/components/Navigation.vue'
import Password from '@/components/Password.vue'
// import UpdateFormular from '@/components/legacy/UpdateFormular.vue'
import TitleWithImage from '@/components/TitleWithImage.vue'
import ZahlenZiehenFilters from '@/components/legacy/ZahlenZiehenFilters.vue'

export default {
  name: 'ZahlenZiehen',
  components: {
    NewArticleList,
    Navigation,
    Password,
    // UpdateFormular,
    TitleWithImage,
    ZahlenZiehenFilters
  },
  data() {
    return {

      editingMode: false,

    }
  },
  methods: {

    onload: function () {

      this.$store.dispatch('getData')

    },

    showEditFields: function (id) {

      this.editingMode = true;
      this.currentlyEditing = id;

      this.$refs.updateFormular.fetchArticle(id);

    },

  },
  mounted: function(){

    this.onload();

  },
}
</script>
