<template>

  <Line :chart-options="myChartOptions" :chart-data="myChartData" :chart-id="chartId" :dataset-id-key="datasetIdKey"
    :plugins="plugins" :css-classes="cssClasses" :styles="styles" :width="width" :height="height" />

</template>

<script>
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, LinearScale, CategoryScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, LinearScale, CategoryScale)

export default {
  name: 'LineChart',
  components: { Line },
  props: {
    myChartData: {
      type: Object,
      required: true
    },
    myChartOptions: {
      type: Object,
      required: true
    },
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => { }
    },
    plugins: {
      type: Object,
      default: () => { }
    },

  },
  data() {

    return {


    }
  },

  methods: {

    fetchData() {

      // console.log(this.myChartOptions);

    }

  },

  mounted: function () {

    this.fetchData();
  },
}

</script>