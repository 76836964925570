<template>

  <p v-html="mydate(date)"> </p>

</template>

<script>
export default {
  name: 'TransformDate',
  props: ['date'],
  data() {
    return {

    }
  },
  methods: {

    mydate(datum){

      if(datum){

        let months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']

        let year = datum.slice(0,4);

        let month = datum.slice(5,7)

        let day = datum.slice(8,10)

        return day + '.'  + month + '.'+ year;

      }

    }

  },
  computed: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
